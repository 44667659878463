import {
  AtFlag24,
  BeFlag24,
  CzFlag24,
  DeFlag24,
  EsFlag24,
  FrFlag24,
  IeFlag24,
  NlFlag24,
  PlFlag24,
  PtFlag24,
  SiFlag24,
  UkFlag24,
  UsFlag24,
} from "~/theme/icons";
import { IconProps } from "~/theme/icons/icons.types";

import { CountryCode2 } from "./CountrySelector.types";

import "@formatjs/intl-displaynames/polyfill";
import "@formatjs/intl-displaynames/locale-data/en";

export const DEFAULT_COUNTRY_CODE = CountryCode2.GB;

const region = new Intl.DisplayNames(["en"], { type: "region" });

export const AVAILABLE_COUNTRIES: Record<
  CountryCode2,
  { title: string; icon: (props: IconProps) => JSX.Element; currencySymbol: string }
> = {
  [CountryCode2.AT]: {
    title: region.of(CountryCode2.AT) ?? "",
    icon: AtFlag24,
    currencySymbol: "€",
  },
  [CountryCode2.BE]: {
    title: region.of(CountryCode2.BE) ?? "",
    icon: BeFlag24,
    currencySymbol: "€",
  },
  [CountryCode2.CZ]: {
    title: region.of(CountryCode2.CZ) ?? "",
    icon: CzFlag24,
    currencySymbol: "€",
  },
  [CountryCode2.DE]: {
    title: region.of(CountryCode2.DE) ?? "",
    icon: DeFlag24,
    currencySymbol: "€",
  },
  [CountryCode2.ES]: {
    title: region.of(CountryCode2.ES) ?? "",
    icon: EsFlag24,
    currencySymbol: "€",
  },
  [CountryCode2.FR]: {
    title: region.of(CountryCode2.FR) ?? "",
    icon: FrFlag24,
    currencySymbol: "€",
  },
  [CountryCode2.IE]: {
    title: region.of(CountryCode2.IE) ?? "",
    icon: IeFlag24,
    currencySymbol: "€",
  },
  [CountryCode2.US]: {
    title: region.of(CountryCode2.US) ?? "",
    icon: UsFlag24,
    currencySymbol: "€",
  },
  [CountryCode2.NL]: {
    title: region.of(CountryCode2.NL) ?? "",
    icon: NlFlag24,
    currencySymbol: "€",
  },
  [CountryCode2.PL]: {
    title: region.of(CountryCode2.PL) ?? "",
    icon: PlFlag24,
    currencySymbol: "€",
  },
  [CountryCode2.GB]: {
    title: region.of(CountryCode2.GB) ?? "",
    icon: UkFlag24,
    currencySymbol: "£",
  },
  [CountryCode2.PT]: {
    title: region.of(CountryCode2.PT) ?? "",
    icon: PtFlag24,
    currencySymbol: "€",
  },
  [CountryCode2.SI]: {
    title: region.of(CountryCode2.SI) ?? "",
    icon: SiFlag24,
    currencySymbol: "€",
  },
};

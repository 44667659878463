import EventEmitter from "events";

import { AppConfigurationClient, parseFeatureFlag } from "@azure/app-configuration";
import {
  defaults,
  defaultsDeep,
  memoize,
  set,
  get,
  isEmpty,
  forEach,
  toNumber,
  has,
} from "lodash";

import { EnvVars } from "~/constants/envVars";
import { Locales } from "~/constants/i18n";
import { getData, setData } from "~/services/SharedStorage/SharedStorage.helpers";
import { Nullable, Optional } from "~/types/utility.types";

import {
  CLIENT_SIDE_PREFIX,
  DEFAULT_LOCALE,
  DEFAULT_TTL,
  FEATURE_FLAG_PREFIX,
  FEATURE_FLAG_REGEX,
  getOverridesForLocalDevelopment,
  SERVER_SIDE_PREFIX,
  WEB_APP_PREFIX,
  WEB_APP_REGEX,
} from "./azureConfiguration.constants";
import { getEnvironmentVariable } from "./azureConfiguration.helpers";
import {
  AllPossibleConfigData,
  AppConfig,
  AzureConfigurationClientInterface,
  AzureConfiguratorEvent,
  ClientConfig,
  Dictionary,
  FeatureFlagList,
  Protected,
  ServerOnlyConfig,
} from "./azureConfiguration.types";

const isLocal = () =>
  getEnvironmentVariable(EnvVars.NEXT_PUBLIC_LOCAL_DEV)?.toLowerCase() === "true";

export class AzureConfigurator extends EventEmitter {
  static readonly event = AzureConfiguratorEvent;
  static instance = new AzureConfigurator();

  /** Only for local development, not supposed to be pushed */
  private static mockedConfigValues: Nullable<AppConfig>;
  private static mockedFlagValues: Nullable<FeatureFlagList>;

  private static initPromise: Promise<void>;

  private static get clientCache() {
    return get(getData(), ["publicRuntimeConfig", "azureConfig"]);
  }

  private static get serverCache() {
    return get(getData(), ["serverRuntimeConfig", "azureConfig"]);
  }

  private static get lastUpdate(): Optional<number> {
    return AzureConfigurator.clientCache?.timestamp;
  }

  static get isInitialized(): boolean {
    return !!AzureConfigurator.instance.client;
  }

  static get isReceived(): boolean {
    return !!AzureConfigurator.lastUpdate;
  }

  private static get configTtl() {
    return toNumber(AzureConfigurator.getConfig()?.configTtl) || DEFAULT_TTL;
  }

  private static getMemoizedByLocaleParamsFunction = <ReturnType>(
    func: (locale?: string) => ReturnType
  ) =>
    memoize((locale?: string) => {
      const memoByTime = memoize(
        () => {
          memoByTime?.cache?.clear?.();
          return memoize(
            () => func(locale),
            () => IS_SERVER
          );
        },
        () => AzureConfigurator.lastUpdate
      );
      return memoByTime;
    });

  private static getFeatureFlagsMemo =
    AzureConfigurator.getMemoizedByLocaleParamsFunction<FeatureFlagList>(
      (locale?: string) => {
        return defaults(
          {},
          AzureConfigurator.clientCache?.[locale ?? DEFAULT_LOCALE]?.featureFlagList,
          AzureConfigurator.clientCache?.default?.featureFlagList
        );
      }
    );

  private static getAppConfigMemo =
    AzureConfigurator.getMemoizedByLocaleParamsFunction<AllPossibleConfigData>(
      (locale?: string) => {
        const localOverrides = isLocal() ? getOverridesForLocalDevelopment() : {};

        const publicPart = defaultsDeep(
          {},
          AzureConfigurator.clientCache?.[locale ?? DEFAULT_LOCALE],
          AzureConfigurator.clientCache?.default,
          localOverrides
        );

        const featureFlagList = defaults(
          {},
          AzureConfigurator.clientCache?.[locale ?? DEFAULT_LOCALE]?.featureFlagList,
          AzureConfigurator.clientCache?.default?.featureFlagList
        );
        if (!isEmpty(featureFlagList)) {
          publicPart.featureFlagList = featureFlagList;
        }

        const result = !IS_SERVER
          ? publicPart
          : defaultsDeep(
              {},
              AzureConfigurator.serverCache?.[locale ?? DEFAULT_LOCALE],
              AzureConfigurator.serverCache?.default,
              publicPart
            );

        return isLocal() ? defaultsDeep({}, localOverrides, result) : result;
      }
    );

  static getConfig(locale = DEFAULT_LOCALE): Optional<AllPossibleConfigData> {
    const lowLocale = locale.toLowerCase();
    const data = AzureConfigurator.getAppConfigMemo(lowLocale)()();

    if (data.territoriesLocale && !Array.isArray(data.territoriesLocale)) {
      const validLocales = (data.territoriesLocale as unknown as string)
        .toLowerCase()
        .split(",")
        .filter((str) => str in Locales && str !== Locales.UNKNOWN) as Locales[];
      data.territoriesLocale = validLocales ?? [];
    }

    return isLocal()
      ? defaultsDeep(
          {},
          {
            ...AzureConfigurator.mockedConfigValues,
            featureFlagList: AzureConfigurator.mockedFlagValues,
          },
          data
        )
      : data;
  }

  static getFeatureFlags(locale = DEFAULT_LOCALE): Optional<FeatureFlagList> {
    const lowLocale = locale.toLowerCase();
    const data = AzureConfigurator.getFeatureFlagsMemo(lowLocale)()();
    return isLocal() ? defaults({}, this.mockedFlagValues, data) : data;
  }

  static async init(client?: AzureConfigurationClientInterface): Promise<void> {
    if (AzureConfigurator.initPromise) {
      return AzureConfigurator.initPromise;
    }

    AzureConfigurator.initPromise = AzureConfigurator.instance.initInstance(client);

    return AzureConfigurator.initPromise;
  }

  private static clearCache(): void {
    const config = getData();
    delete config?.serverRuntimeConfig.azureConfig;
    delete config?.publicRuntimeConfig.azureConfig;
    setData(config);
  }

  static destruct(): void {
    if (IS_SERVER) {
      AzureConfigurator.instance.destructInstance();
    }
    AzureConfigurator.clearCache();
  }

  private client: Nullable<AzureConfigurationClientInterface> = null;
  private updateCycle: Nullable<ReturnType<typeof setInterval>> = null;

  private async initInstance(client?: AzureConfigurationClientInterface) {
    AzureConfigurator.destruct();

    if (IS_SERVER) {
      if (client) {
        this.client = client;
      } else {
        this.client = new AppConfigurationClient(
          getEnvironmentVariable(EnvVars.AZURE_CONFIGURATION_CONNECTION_STRING) ?? ""
        );
      }
    }

    if (IS_SERVER) {
      await this.startUpdateCycle();
    }
  }

  private async getFeatureFlagsData(): Promise<Dictionary<FeatureFlagList>> {
    const result: Dictionary<FeatureFlagList> = {};
    if (!IS_SERVER || !this.client) {
      return result;
    }

    const featureFlagsResult = this.client.listConfigurationSettings({
      keyFilter: `${FEATURE_FLAG_PREFIX}/*`,
    });

    for await (const setting of featureFlagsResult) {
      const { key, value, label } = parseFeatureFlag(setting);
      const keyWithoutPrefix = key.replace(FEATURE_FLAG_REGEX, "");
      const receivedLocale = label ?? DEFAULT_LOCALE;
      const path = keyWithoutPrefix.split("/");
      set(result, [receivedLocale, ...path], {
        ...get(result, path),
        ...value,
      });
    }
    return result;
  }

  private async getWebAppConfigVariablesData(): Promise<
    Dictionary<Protected<ServerOnlyConfig, ClientConfig>>
  > {
    const result: Dictionary<Protected<ServerOnlyConfig, ClientConfig>> = {};
    if (!IS_SERVER || !this.client) {
      return result;
    }

    const requestResult = this.client.listConfigurationSettings({
      keyFilter: `${WEB_APP_PREFIX}/*`,
    });

    for await (const setting of requestResult) {
      const { key, value, label } = setting;
      const receivedLocale = label ?? DEFAULT_LOCALE;
      const keyWithoutPrefix = key.replace(WEB_APP_REGEX, "");

      let actualValue = value;
      let privacy = "server";
      let path = [];

      if (
        keyWithoutPrefix.startsWith(CLIENT_SIDE_PREFIX) ||
        keyWithoutPrefix.startsWith(SERVER_SIDE_PREFIX)
      ) {
        const [privacyKey, ...pathArray] = keyWithoutPrefix.split("/");
        privacy = privacyKey;
        path = pathArray;
      } else {
        path = keyWithoutPrefix.split("/");
      }

      if (has(getOverridesForLocalDevelopment(), path)) {
        actualValue = actualValue ?? undefined;
      }
      set(result, [receivedLocale, privacy, ...path], actualValue);
    }

    return result;
  }

  private async updateData(): Promise<unknown> {
    if (!IS_SERVER || !this.client) {
      return;
    }

    const currentTimestamp = Date.now();
    const resultList = await Promise.allSettled([
      this.getFeatureFlagsData(),
      this.getWebAppConfigVariablesData(),
    ]);
    const [featureFlagsResult, webAppConfigResult] = resultList.map((item) => {
      return item.status === "fulfilled" ? item.value : undefined;
    }) as [
      Optional<Dictionary<FeatureFlagList>>,
      Optional<Protected<Dictionary<ServerOnlyConfig>, Dictionary<ClientConfig>>>
    ];

    const isFirstRequest = !AzureConfigurator.isReceived;

    const config = getData();
    forEach(webAppConfigResult, (value, locale) => {
      set(config, ["publicRuntimeConfig", "azureConfig", locale], value?.client);
      set(config, ["serverRuntimeConfig", "azureConfig", locale], value?.server);
    });

    forEach(featureFlagsResult, (value, locale) => {
      set(
        config,
        ["publicRuntimeConfig", "azureConfig", locale, "featureFlagList"],
        value
      );
    });

    set(
      config,
      ["publicRuntimeConfig", "azureConfig", "timestamp"],
      currentTimestamp
    );

    setData(config);
    if (isFirstRequest) {
      this.emit(AzureConfiguratorEvent.INIT);
    } else {
      this.emit(AzureConfiguratorEvent.UPDATE);
    }
    this.emit(AzureConfiguratorEvent.SET);
    return config;
  }

  private stopUpdateCycle(): void {
    if (this.updateCycle !== null) {
      clearTimeout(this.updateCycle);
      this.updateCycle = null;
    }
  }

  private async startUpdateCycle(): Promise<void> {
    if (this.client) {
      const iteration = async () => {
        await this.updateData().finally(() => {
          if (IS_SERVER) {
            this.updateCycle = setTimeout(iteration, AzureConfigurator.configTtl);
          }
        });
      };
      await iteration();
    }
  }

  private destructInstance(): void {
    if (IS_SERVER) {
      this.stopUpdateCycle();
      this.client = null;
    }
  }
}

if (IS_SERVER && !AzureConfigurator.isInitialized) {
  AzureConfigurator.init();
}

export default AzureConfigurator;

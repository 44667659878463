import React from "react";

import {
  Box,
  Typography,
  Drawer,
  MenuItem,
  IconButton,
  List,
  styled,
} from "@mui/material";

import { CountrySelectorChildrenProps } from "~/bff/components/CountrySelectorChildren";
import { Image } from "~/components/Image";
import { Close24 } from "~/theme/icons";
import { getFormatMedia } from "~/theme/utils/formatMedia";
import { getImageSrc } from "~/utils/image";
import { prepareTextAttribute } from "~/utils/prepareTextAttribute";
import { getTestAutomationProps } from "~/utils/testUtils";

export interface CountrySelectorPopupProps {
  value?: CountrySelectorChildrenProps;
  title?: string;
  description?: string;
  isOpen?: boolean;
  className?: string;
  onClick: (country: CountrySelectorChildrenProps) => void;
  onClose: (isOpen: boolean) => void;
  countries?: CountrySelectorChildrenProps[];
  isMobile?: boolean;
}

const PREFIX = "StyledCountrySelectorPopup";
const classes = {
  popup: `${PREFIX}-popup`,
  countryItemWrap: `${PREFIX}-countryItemWrap`,
  countryItem: `${PREFIX}-countryItem`,
  title: `${PREFIX}-title`,
  description: `${PREFIX}-description`,
  closeButton: `${PREFIX}-closeButton`,
  icon: `${PREFIX}-icon`,
};

const StyledCountrySelectorPopup = styled(Drawer)(({ theme }) => {
  const formatMedia = getFormatMedia(theme);

  return {
    [`& .${classes.popup}`]: {
      padding: theme.spacing(7, 2, 0),
      [formatMedia.BREAKPOINT_TABLET]: {
        padding: theme.spacing(7, 4, 0),
      },
      [formatMedia.BREAKPOINT_DESKTOP]: {
        padding: theme.spacing(4, 6, 0),
      },
    },
    [`& .${classes.countryItemWrap}`]: {
      paddingTop: 0,
    },

    [`& .${classes.countryItem}`]: {
      display: "flex",
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      ...theme.typography.body2,
    },
    [`& .${classes.title}`]: {
      marginBottom: theme.spacing(2),
    },
    [`& .${classes.description}`]: {
      marginBottom: theme.spacing(2),
      maxWidth: "389px",
    },
    [`& .${classes.closeButton}`]: {
      position: "absolute",
      top: theme.spacing(1),
      left: theme.spacing(0.5),
      [formatMedia.BREAKPOINT_TABLET]: {
        left: theme.spacing(2.2),
      },
      [formatMedia.BREAKPOINT_DESKTOP]: {
        left: "auto",
        top: theme.spacing(1.5),
        right: theme.spacing(3.5),
      },
    },
    [`& .${classes.icon}`]: {
      marginRight: theme.spacing(1),
      display: "flex",
      alignItems: "center",
      "& img": {
        width: "22px",
        height: "16px",
      },
    },
  };
});

export const CountrySelectorPopup = ({
  title,
  description,
  isOpen,
  onClick,
  onClose,
  countries,
  isMobile,
  className,
}: CountrySelectorPopupProps) => {
  const handleSelectorClose = () => {
    onClose(false);
  };

  return (
    <StyledCountrySelectorPopup
      open={isOpen}
      className={className}
      onClose={handleSelectorClose}
      anchor={isMobile ? "bottom" : "right"}
      PaperProps={{ className: classes.popup }}
      {...getTestAutomationProps(`country-selector-popup`)}
    >
      <IconButton
        color="inherit"
        aria-label="close button"
        onClick={handleSelectorClose}
        className={classes.closeButton}
        {...getTestAutomationProps("country-selector-popup-close-button")}
      >
        <Close24 />
      </IconButton>
      <Typography variant="h4" className={classes.title}>
        {title}
      </Typography>
      <Typography variant="body1" className={classes.description}>
        {description}
      </Typography>
      <List className={classes.countryItemWrap}>
        {countries?.map((country) => {
          const { countryKey, image, link } = country;

          const handleMenuClick = () => {
            onClick(country);
            handleSelectorClose();
          };

          return (
            <MenuItem
              value={countryKey ?? ""}
              onClick={handleMenuClick}
              className={classes.countryItem}
              key={`${countryKey}-${link?.label ?? ""}`}
              {...getTestAutomationProps(`country-selector-${countryKey}`)}
            >
              <Box className={classes.icon}>
                <Image
                  src={getImageSrc(image?.source ?? undefined)}
                  alt={prepareTextAttribute(image?.alt, true)}
                  title={prepareTextAttribute(image?.title)}
                />
              </Box>
              {link?.label ?? ""}
            </MenuItem>
          );
        })}
      </List>
    </StyledCountrySelectorPopup>
  );
};

/* eslint no-process-env: 0 */
import { getData } from "~/services/SharedStorage/SharedStorage.helpers";

export const getEnvironmentVariable = (name: string): string | null => {
  const { publicRuntimeConfig = {}, serverRuntimeConfig = {} } = getData() ?? {};
  const value =
    serverRuntimeConfig[name] || publicRuntimeConfig[name] || process.env[name];
  if (value === null || value === undefined) {
    return null;
  }

  return value;
};

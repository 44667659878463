export enum CountryCode2 {
  AT = "AT",
  BE = "BE",
  CZ = "CZ",
  DE = "DE",
  ES = "ES",
  FR = "FR",
  IE = "IE",
  US = "US",
  NL = "NL",
  PL = "PL",
  GB = "GB",
  PT = "PT",
  SI = "SI",
}

import { EnvVars } from "~/constants/envVars";
import { AllPossibleConfigData } from "~/services/azureConfiguration/azureConfiguration.types";

import { getEnvironmentVariable } from "./azureConfiguration.helpers";

export const FEATURE_FLAG_PREFIX = ".appconfig.featureflag";
export const WEB_APP_PREFIX = "webApp";
export const CLIENT_SIDE_PREFIX = `client`;
export const SERVER_SIDE_PREFIX = `server`;

export const FEATURE_FLAG_REGEX = new RegExp(`^${FEATURE_FLAG_PREFIX}/`);
export const WEB_APP_REGEX = new RegExp(`^${WEB_APP_PREFIX}/`);

export const DEFAULT_LOCALE = "default";
export const DEFAULT_TTL = 60 * 1000;

export const getOverridesForLocalDevelopment = (): AllPossibleConfigData => ({
  frontEndBaseUrl: getEnvironmentVariable(
    EnvVars.NEXT_PUBLIC_LOCAL_FRONT_END_BASE_URL
  ),
  apiBaseUrl: IS_SERVER
    ? getEnvironmentVariable(EnvVars.LOCAL_SERVER_SIDE_API_BASE_URL)
    : getEnvironmentVariable(EnvVars.NEXT_PUBLIC_LOCAL_CLIENT_API_BASE_URL),
  technical: {
    debug: getEnvironmentVariable(EnvVars.DEBUG),
    testIdsAreEnabled: getEnvironmentVariable(EnvVars.TEST_IDS_ENABLED),
    minifyJsIsEnabled: getEnvironmentVariable(EnvVars.MINIFY_JS_ENABLED),
    minifyCssIsEnabled: getEnvironmentVariable(EnvVars.MINIFY_CSS_ENABLED),
  },
});

import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from "react";

import { ACTION } from "./useBrowserData.constants";
import { INTITIAL_STATE, reducer } from "./useBrowserData.reducer";
import { UseBrowserDataReturnType } from "./useBrowserData.types";

const BrowserDataContext = createContext<UseBrowserDataReturnType>({
  ...INTITIAL_STATE,
  setIsBrowserNavigation: () => {
    /* do nothing */
  },
});

export const BrowserDataProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, INTITIAL_STATE);

  const setIsBrowserNavigation = useCallback(
    (isBrowserNavigation: boolean) => {
      dispatch({
        type: ACTION.UPDATE_IS_BROWSER_NAVIGATION,
        payload: isBrowserNavigation,
      });
    },
    [dispatch]
  );

  const popstateEventHandler = useCallback(() => {
    setIsBrowserNavigation(true);
  }, [setIsBrowserNavigation]);

  useEffect(() => {
    window.addEventListener("popstate", popstateEventHandler);

    return () => {
      window.removeEventListener("popstate", popstateEventHandler);
    };
  }, [popstateEventHandler]);

  return (
    <BrowserDataContext.Provider
      value={{
        ...state,
        setIsBrowserNavigation,
      }}
    >
      {children}
    </BrowserDataContext.Provider>
  );
};

export const useBrowserData = (): UseBrowserDataReturnType =>
  useContext(BrowserDataContext);

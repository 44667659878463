import React, { useCallback, useState } from "react";

import { cx } from "@emotion/css";
import { Box, Typography, styled } from "@mui/material";

import { CountrySelectorProps } from "~/bff/components/CountrySelector";
import { CountrySelectorChildrenProps } from "~/bff/components/CountrySelectorChildren";
import { CountrySelectorPopup } from "~/components/CountrySelector/components/CountrySelectorPopup";
import { Image } from "~/components/Image";
import { COLOR_PRIMARY_BLACK } from "~/theme/constants/colors";
import { Down24 } from "~/theme/icons";
import { getFormatMedia } from "~/theme/utils/formatMedia";
import { getImageSrc } from "~/utils/image";
import { prepareTextAttribute } from "~/utils/prepareTextAttribute";
import { getTestAutomationProps } from "~/utils/testUtils";

export interface CountrySelectorPropsExtended extends CountrySelectorProps {
  value?: CountrySelectorChildrenProps;
  onChange: (country: CountrySelectorChildrenProps) => void;
  countries?: CountrySelectorChildrenProps[];
  className?: string;
  isMobileView?: boolean;
}

const PREFIX = "StyledCountrySelector";
const classes = {
  root: `${PREFIX}-root`,
  icon: `${PREFIX}-icon`,
  currentCountryLabel: `${PREFIX}-currentCountryLabel`,
  downIcon: `${PREFIX}-downIcon`,
  item: `${PREFIX}-item`,
};

const StyledCountrySelector = styled(Box)(({ theme }) => {
  const formatMedia = getFormatMedia(theme);

  return {
    [`&.${classes.root}`]: {
      border: `1px solid rgba(0, 0, 0, 0.23)`,
      height: "48px",
      display: "flex",
      alignItems: "center",
      paddingLeft: theme.spacing(1.7),
      color: COLOR_PRIMARY_BLACK,
      cursor: "pointer",
      [formatMedia.BREAKPOINT_WIDE_DESKTOP]: {
        maxWidth: "240px",
      },
      "&:hover": {
        borderColor: COLOR_PRIMARY_BLACK,
      },
    },
    [`& .${classes.icon}`]: {
      marginRight: theme.spacing(1),
      display: "flex",
      alignItems: "center",
      "& img": {
        width: "22px",
        height: "16px",
      },
    },
    [`& .${classes.currentCountryLabel}`]: {
      flex: 1,
    },
    [`& .${classes.downIcon}`]: {
      pointerEvents: "none",
      marginTop: "5px",
      marginRight: "8px",
    },
    [`& .${classes.item}`]: {
      display: "flex",
      alignItems: "center",
    },
  };
});

export const CountrySelector = ({
  value,
  onChange,
  countries,
  className,
  isMobileView,
  title,
  description,
}: CountrySelectorPropsExtended) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleTogglePopup = useCallback((isOpen: boolean) => {
    setIsOpen(isOpen);
  }, []);

  const handleChange = useCallback(
    (country: CountrySelectorChildrenProps) => {
      if (onChange) {
        onChange(country);
      }
    },
    [onChange]
  );

  return (
    <>
      <StyledCountrySelector
        onClick={() => handleTogglePopup(true)}
        className={cx(classes.root, className)}
        {...getTestAutomationProps(`country-selector-popup-button`)}
      >
        <Box className={classes.icon}>
          <Image
            src={getImageSrc(value?.image?.source ?? undefined)}
            alt={prepareTextAttribute(value?.image?.alt, true)}
            title={prepareTextAttribute(value?.image?.title)}
          />
        </Box>
        <Typography variant="body1" className={classes.currentCountryLabel}>
          {value?.link?.label}
        </Typography>
        <Box className={classes.downIcon}>
          <Down24 />
        </Box>
      </StyledCountrySelector>
      <CountrySelectorPopup
        title={title ?? ""}
        description={description ?? ""}
        onClick={handleChange}
        onClose={handleTogglePopup}
        countries={countries}
        value={value}
        className={className}
        isOpen={isOpen}
        isMobile={isMobileView}
      />
    </>
  );
};

/* eslint no-process-env: 0 */
type configs = "publicRuntimeConfig" | "serverRuntimeConfig";
declare global {
  interface Window {
    __GLOBAL_DATA__: Record<"publicRuntimeConfig" | "processEnv", any>; // eslint-disable-line @typescript-eslint/no-explicit-any, @typescript-eslint/naming-convention
  }
}

const CONFIG_STORAGE_NAME = "__GLOBAL_DATA__";

export const CONFIG_STORAGE_ELEMENT_ID = `${CONFIG_STORAGE_NAME}_element`;
export const PUBLIC_RUNTIME_CONFIG_NAME = "publicRuntimeConfig";
export const SERVER_RUNTIME_CONFIG_NAME = "serverRuntimeConfig";

const GLOBAL_DATA: Record<configs, unknown> = {
  // eslint-disable-line @typescript-eslint/no-explicit-any, @typescript-eslint/naming-convention
  [PUBLIC_RUNTIME_CONFIG_NAME]: {},
  [SERVER_RUNTIME_CONFIG_NAME]: {},
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getData<T = any>(): T {
  if (!IS_SERVER && !IS_TEST) {
    if (!window[CONFIG_STORAGE_NAME]) {
      const element = window.document.getElementById(CONFIG_STORAGE_ELEMENT_ID);
      window[CONFIG_STORAGE_NAME] = element
        ? JSON.parse(element.textContent as string)
        : null;
    }

    // eslint-disable-next-line no-restricted-globals
    const dataStorage = window[CONFIG_STORAGE_NAME] || {};
    return dataStorage as T;
  }
  return GLOBAL_DATA as T;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function setData<T = any>(value: Record<configs, T>): void {
  GLOBAL_DATA[PUBLIC_RUNTIME_CONFIG_NAME] = value[PUBLIC_RUNTIME_CONFIG_NAME];
  GLOBAL_DATA[SERVER_RUNTIME_CONFIG_NAME] = value[SERVER_RUNTIME_CONFIG_NAME];
}

export function getDataForClient() {
  const data = getData();
  return {
    [PUBLIC_RUNTIME_CONFIG_NAME]: data[PUBLIC_RUNTIME_CONFIG_NAME],
  };
}

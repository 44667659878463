"use client";
import { useParams } from "next/navigation";

import { getPageLocale } from "~/utils/locales";

import { AzureConfigurator } from "./azureConfiguration";

export interface UseAzureConfigType {
  appConfig: ReturnType<typeof AzureConfigurator.getConfig>;
  featureFlags: ReturnType<typeof AzureConfigurator.getFeatureFlags>;
  locale?: string;
}

export const useAzureConfig = (): UseAzureConfigType => {
  const params = useParams();
  const pageLocale = getPageLocale(params?.locale as string).toLowerCase();

  return {
    appConfig: AzureConfigurator.getConfig(pageLocale),
    featureFlags: AzureConfigurator.getFeatureFlags(pageLocale),
    locale: pageLocale,
  };
};

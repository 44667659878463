import { ACTION } from "./useBrowserData.constants";
import { UseBrowserDataAction, UseBrowserDataState } from "./useBrowserData.types";

export const INTITIAL_STATE: UseBrowserDataState = {
  isBrowserNavigation: false,
};

export const reducer = (
  state: UseBrowserDataState,
  action: UseBrowserDataAction
) => {
  if (action.type === ACTION.UPDATE_IS_BROWSER_NAVIGATION) {
    return {
      ...state,
      isBrowserNavigation: action.payload,
    };
  }

  return state;
};

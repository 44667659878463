import { ConfigurationSetting, FeatureFlagValue } from "@azure/app-configuration";

import { Locales } from "~/constants/i18n";
import { Nullable } from "~/types/utility.types";

type Config<T = unknown> = Record<string, T>;

export type Dictionary<T = unknown> = Record<string, T> & {
  default?: T;
};

export interface Protected<ST = unknown, CT = ST> {
  server?: ST;
  client?: CT;
}

export interface FeatureFlagList {
  [k: string]: FeatureFlagValue;
}

export interface WithFeatureFlags {
  featureFlagList?: FeatureFlagList;
}

export enum NewRelicEnvs {
  DEV = "dev",
  PROD = "prod",
}

export interface ClientConfig extends Config {
  testKey?: string;
  technical?: {
    debug?: Nullable<string>;
    testIdsAreEnabled?: Nullable<string>;
    minifyJsIsEnabled?: Nullable<string>;
    minifyCssIsEnabled?: Nullable<string>;
  };
  apiBaseUrl?: Nullable<string>;
  frontEndBaseUrl?: Nullable<string>;
  gtm?: {
    id?: string;
  };
  oneTrust?: {
    id?: string;
  };
  cookieDomain?: string;
  newRelic?: {
    env?: NewRelicEnvs;
  };
  territoriesLocale?: Locales[];
}

export interface ServerOnlyConfig extends Config {
  testKey?: string;
  apiBaseUrl?: Nullable<string>;
  configTtl?: number;
}

export type ClientAvailableConfigData = ClientConfig & WithFeatureFlags;

export type AppConfig = ClientConfig & ServerOnlyConfig;

export type AllPossibleConfigData = AppConfig & WithFeatureFlags;

export enum AzureConfiguratorEvent {
  INIT = "INIT",
  UPDATE = "UPDATE",
  SET = "SET",
}

export type ListConfigurationSettingsType = (params: {
  keyFilter: string;
}) => AsyncIterableIterator<ConfigurationSetting>;

export interface AzureConfigurationClientInterface {
  listConfigurationSettings: ListConfigurationSettingsType;
}
